export const STATUSES = Object.freeze({
  Confirmed: "CONFIRMED",
  Potential: "POTENTIAL",
  Irrelevant: "IRRELEVANT",
  Unknown: "UNKNOWN",
  Unresolved: "UNRESOLVED",
  None: "NONE",
});

export  const STATUSES_LIST = Object.values(STATUSES).map(el => el.toLocaleLowerCase());

export const STATUS_BUTTON_CLASSES = Object.freeze({
  [STATUSES.Confirmed.toLowerCase()]: "text-lime-600 border-lime-600",
  [STATUSES.Potential.toLowerCase()]: "text-custom-orange border-custom-orange",
  [STATUSES.Irrelevant.toLowerCase()]: "text-custom-red border-custom-red",
  [STATUSES.Unknown.toLowerCase()]: "text-gray-400 border-gray-400",
  [STATUSES.Unresolved.toLowerCase()]: "text-gray-400 border-gray-400",
  [STATUSES.None.toLowerCase()]: "text-primary border-primary-focus"
});

export const STATUS_BORDER_CLASSES = Object.freeze({
  [STATUSES.Confirmed.toLowerCase()]: "border-lime-600",
  [STATUSES.Potential.toLowerCase()]: "border-custom-orange",
  [STATUSES.Irrelevant.toLowerCase()]: "border-custom-red",
  [STATUSES.Unknown.toLowerCase()]: "border-gray-400",
  [STATUSES.Unresolved.toLowerCase()]: "border-gray-400",
  [STATUSES.None.toLowerCase()]: "border-gray-400",
});

export const STATUS_SHADOW_CLASSES = Object.freeze({
  [STATUSES.Confirmed.toLowerCase()]: "box-shadow: 0 4px 6px rgba(132, 204, 22, 0.6)",
  [STATUSES.Potential.toLowerCase()]: "box-shadow: 0 4px 6px rgba(252, 103, 19, 0.6)",
  [STATUSES.Irrelevant.toLowerCase()]: "box-shadow: 0 4px 6px rgba(235, 49, 49, 0.6)",
  [STATUSES.Unknown.toLowerCase()]: "box-shadow: 0 4px 6px rgba(209, 213, 219, 0.6)",
  [STATUSES.Unresolved.toLowerCase()]: "box-shadow: 0 4px 6px rgba(209, 213, 219, 0.6)",
  [STATUSES.None.toLowerCase()]: "box-shadow: 0 4px 6px rgba(209, 213, 219, 0.6)",
});
