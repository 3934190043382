<template>
  <ModalContent
      ref="search-parameters-content"
      name="search-parameters-content"
      :showHeader="false"
      submit-button-text="Close"
      v-bind="$attrs"
      v-on="$listeners"
      @submit="onContentHide"
  >
    <div class="p-6">
      <h3 class="font-semibold">Filter</h3>

      <div class="my-4">
        <FormulateInput
          v-model="queryString"
          type="text"
          label="Search"
          class="flex-1"
          :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
          disabled
        />
      </div>

    <div class="flex items-start w-full gap-4">
      <div class="flex flex-col flex-1 space-y-2 w-1/4">
        <label class="text-gray-500">Jurisdiction</label>
        <vue-multiselect
          v-model="jurisdictionModel"
          v-bind="dropdownConfig"
          multiple
          :options="jurisdictionOptions"
        />
      </div>

      <div class="flex flex-col flex-1 space-y-2 w-1/4">
        <label class="text-gray-500">State</label>
        <vue-multiselect
          v-model="subJurisdictionModel"
          v-bind="dropdownConfig"
          multiple
          :options="subJurisdictionOptions"
        />
      </div>

      <div class="flex flex-col flex-1 space-y-2 w-1/4">
        <label class="text-gray-500">Content</label>
        <vue-multiselect
          v-model="contentTypeModel"
          v-bind="dropdownConfig"
          multiple
          :options="contentTypeOptions"
        />
      </div>

      <div class="flex flex-col flex-1 space-y-2 w-1/4">
        <label class="text-gray-500">Sort by</label>
        <vue-multiselect
          v-model="sortModel"
          v-bind="dropdownConfig"
          :options="sortOptions"
        />
      </div>
    </div>
  </div>
  </ModalContent>
</template>

<script>
import { mapGetters } from "vuex";
import ModalContent from "@shared/modal-content";
import VueMultiselect from "@shared/saas-components/vue-multiselect/index";
import { DROPDOWN_CONFIG } from "../constants";

export default {
  name: "VLexSearchParameters",

  components: {
    ModalContent,
    VueMultiselect,
  },

  computed: {
    ...mapGetters({
      requestPayload: "VLexStore/getRequestPayload",
      queryData: "VLexStore/getQuery",
      searchOptions: "VLexStore/getSearchOptions",
    }),

    queryString() {
      return this.queryObject?.query ?? "";
    },

    jurisdictionModel() {
      return this.jurisdictionOptions
        .filter(el => this.queryObject.jurisdiction?.includes(el?.value));
    },

    jurisdictionOptions() {
      return this.searchOptions?.jurisdiction ?? [];
    },

    subJurisdictionModel() {
      return this.subJurisdictionOptions
        .filter(el => this.queryObject.sub_jurisdiction?.includes(el?.value));
    },

    subJurisdictionOptions() {
      return this.searchOptions?.sub_jurisdiction ?? [];
    },

    contentTypeModel() {
      return this.contentTypeOptions
        .filter(el => this.queryObject.content_type?.includes(el?.value));
    },

    contentTypeOptions() {
      return this.searchOptions?.content_type ?? [];
    },

    sortModel() {
      return this.sortOptions.filter(el => this.queryObject.sort === el?.value);
    },

    sortOptions() {
      return this.searchOptions?.sort ?? [];
    },

    queryObject() {
      return this.requestPayload ?? {};
    },

    dropdownConfig() {
      return {
        ...DROPDOWN_CONFIG,
        disabled: true,
        placeholder: "",
      };
    },
  },

  methods: {
    onContentHide() {
      this.$refs["search-parameters-content"].$modal.hide("search-parameters-content")
    },
  }
}
</script>
