import {
  STATUSES,
  STATUSES_LIST,
  STATUS_BUTTON_CLASSES,
  STATUS_BORDER_CLASSES,
  STATUS_SHADOW_CLASSES,
} from "./constants.js";

export const checkIsNoneStatus = status => {
  return !STATUSES_LIST.includes(status) || status === STATUSES.None.toLowerCase();
}

export const getStatusButtonClasses = (cardStatus) => {
  const status = cardStatus.toLowerCase();
  if (checkIsNoneStatus(status)) {
    return STATUS_BUTTON_CLASSES[STATUSES.Unresolved.toLowerCase()];
  }

  return  STATUS_BUTTON_CLASSES[status];
};

export const getBorderColorClass = (cardStatus) => {
  const status = cardStatus.toLowerCase();
  if (checkIsNoneStatus(status)) {
    return STATUS_BORDER_CLASSES[STATUSES.Unresolved.toLowerCase()];
  }

  return  STATUS_BORDER_CLASSES[status];
};

export const getHighlightShadowClass = (cardStatus) => {
  const status = cardStatus.toLowerCase();
  if (checkIsNoneStatus(status)) {
    return STATUS_SHADOW_CLASSES[STATUSES.Unresolved.toLowerCase()];
  }

  return  STATUS_SHADOW_CLASSES[status];
};

export const resolveCardStatus = (key) => {
  if (key === "FALSE MATCHES") return "IRRELEVANT";
  else if (key === "UNRESOLVED" || key === "UNKNOWN" || key === "NONE") return "None";

  return key;
};
