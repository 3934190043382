<template>
  <section class="flex flex-col justify-start self-start overflow-hidden max-h-full w-1/4 max-w-sm flex-shrink-0 border border-solid border-gray-300 rounded-lg overflow-hidden">
    <header class="flex items-center justify-between py-2 px-4 border-solid border-gray-200 bg-gray-50">
      <p class="text-black text-base font-semibold tracking-wide"> Filters</p>
      <button type="button" @click="closeFiltersPanel">
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x rounded-full p-2 bg-gray-200 text-gray-600 cursor-pointer hover:text-brand-hover">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </header>

    <div class="flex flex-col flex-grow overflow-hidden">
        <div class="flex flex-col">
        <div class="flex justify-between items-center mb-2 pt-2 px-4">
          <p class="text-gray-500">Applied Filters</p>
          <button type="button" class="ml-auto block cursor-pointer text-brand text-sm" @click="clearFilters">
            Clear filters
          </button>
        </div>

        <div class="applied-filters-tags overflow-y-auto max-h-40 px-4 scroll-bar">
          <span
            class="text-xs inline-block py-1 px-2 uppercase rounded text-white bg-brand last:mr-0 mr-2 mb-1"
            v-for="filter in appliedFilters"
            :key="filter"
          >
            {{ filter }}
             <font-awesome-icon icon="times" class="ml-2 self-center cursor-pointer hover:opacity-70" @click="handleFilterApplied(filter)" />
          </span>
        </div>
      </div>

    <div class="flex-col flex-grow gap-y-2 justify-start items-start p-4 overflow-y-auto overflow-x-hidden scroll-bar">
      <div class="mt-2">
        <label class="text-gray-500 tracking-wide text-sm">Filter results</label>
        <Input
          :changeCase="true"
          style="border: 1px solid #e8e8e8"
          :shadow="false"
          class="rounded-lg h-10 mt-1"
          placeholder="Search Name"
          borderColor="#e8e8e8"
          bg="#fff"
          margin="0.25rem 0 0 0"
          :style="{ width: '100%' }"
          icon="search"
          v-model="model.query.value"
        />
      </div>

      <div class="mt-2">
        <label class="text-gray-500 tracking-wide text-sm">Jurisdiction</label>
        <vue-multiselect
          v-model="model.jurisdiction.value"
          v-bind="dropdownConfig"
          checkboxes
          multiple
          :options="jurisdictionOptions"
          placeholder="Select Jurisdiction"
          :disabled="!jurisdictionOptions.length"
        />
      </div>

      <div class="flex flex-col gap-2 mt-2">
        <label class="text-gray-500 tracking-wide text-sm">Date</label>
        <DatePicker
          v-model="model.dateRange"
          range
          editable
          :open-date="new Date()"
          placeholder="Select Date"
          range-separator="|"
          clearable
          value-type="timestamp"
          format="DD/MM/YYYY"
          class="date-picker w-full border-gray-100 h-12 w-40 rounded-sm"
          @clear="handleClear"
        >
          <template  #input>
            <FormulateInput
              :value="model.dateRange"
              type="text"
              placeholder="Select Date"
              class="flex-1 h-10"
              :wrapper-class="(context, classes) => ['h-10'].concat(classes)"
              :element-class="(context, classes) => ['flex-1 min-w-full h-10'].concat(classes)"
            />
          </template>
        </DatePicker>
      </div>

      <div class="mt-4">
        <label class="text-gray-500 tracking-wide text-sm">Docket Number</label>
        <vue-multiselect
          v-model="model.docketNumber.value"
          v-bind="dropdownConfig"
          :options="docketNumberOptions"
          placeholder="Select Docket Number"
          :disabled="!docketNumberOptions.length"
        />
      </div>
    </div>
    </div>

    <div class="flex items-center justify-between mt-auto p-4 border-t border-0 border-solid border-gray-200 bg-gray-50">
      <button @click="clearFilters" class="mr-2 border-none py-2 px-4 bg-gray-200 cursor-pointer rounded text-sm h-full flex items-center text-brand hover:bg-brand hover:text-white">
        Reset
      </button>
      <button
        class="border-none rounded text-sm h-full py-2 px-4 flex items-center text-white bg-primary"
        @click="applyFilters"
      >
        <font-awesome-icon icon="filter" class="w-4 mr-1" />
        Apply Filter
      </button>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import VueMultiselect from "@shared/saas-components/vue-multiselect/index";
import Input from "@shared/saas-components/input/index";
import { resolveModel } from "../utils";

export default {
  name: "VLexFiltersPanel",

  components: {
    Input,
    VueMultiselect,
    DatePicker,
  },

  data() {
    return {
      model: {
        query: { value: "" },
        jurisdiction: { value: [] },
        docketNumber: { value: {} },
        dateRange: []
      },
    }
  },

  computed: {
    ...mapGetters({
      filters: "VLexStore/getFilters",
      filtersOptions: "VLexStore/getFiltersOptions",
      paginationData: "VLexStore/getPaginationData",
      filteredSearchResults: "VLexStore/getFilteredSearchResults",
      isRunAllQueries: "VLexStore/getIsRunAllQueries",
    }),

    dropdownConfig() {
      return {
        checkboxes: false,
        closeOnSelect: true,
        groupLabel: "group",
        groupSelect: true,
        groupValues:"categories",
        label: "name",
        limit: 3,
        multiple: false,
        showLabels: false,
        tagPlaceholder: "",
        trackBy: "name",
      }
    },

    jurisdictionOptions() {
      return this.filtersOptions.jurisdiction ?? [];
    },

    docketNumberOptions() {
      return this.filtersOptions.docketNumber ?? [];
    },

    appliedFilters() {
      return [
        ...this.jurisdictionOptions[0]?.categories
          ?.filter(el => this.filters?.jurisdiction?.some(itm => itm === el?.value))
          ?.map(el => el.name) ?? []
      ];
    },

    isFiltersEmpty() {
      return !this.model.query.value.length
        && !this.model.jurisdiction.value.length
        && !this.model.dateRange.length
        && !this.model.docketNumber.value.value?.length;
    }
  },

  methods: {
    ...mapActions({
      resetFilters: "VLexStore/resetFilters"
    }),
    ...mapMutations({
      TOGGLE_LOADING: "VLexStore/TOGGLE_LOADING",
      SET_REQUEST_HEADERS: "VLexStore/SET_REQUEST_HEADERS",
      SET_FILTERS: "VLexStore/SET_FILTERS",
      SET_FILTER_PARAM: "VLexStore/SET_FILTER_PARAM",
      SET_FILTERS_APPLIED: "VLexStore/SET_FILTERS_APPLIED",
      SET_PAGINATION: "VLexStore/SET_PAGINATION",
      SET_SELECTED_CARD_ID: "VLexStore/SET_SELECTED_CARD_ID",
    }),

    handleFilterApplied(filter) {
      const { jurisdiction } = this.model;
      const index = jurisdiction.value.findIndex(el => el.name === filter);
      this.model.jurisdiction.value.splice(index, 1);

      this.SET_FILTER_PARAM({
        field: "jurisdiction",
        value: resolveModel(jurisdiction)
      });

      if (this.isFiltersEmpty) {
        this.clearFilters();
      }
    },

    applyFilters() {
      const { query, jurisdiction, docketNumber } = this.model;

      this.SET_FILTERS({
        query: query.value,
        jurisdiction: resolveModel(jurisdiction),
        date: {
          start: this.model.dateRange[0] ? new Date(this.model.dateRange[0]) : "",
          end: this.model.dateRange[1] ? new Date(this.model.dateRange[1]) : "",
        },
        docketNumber: resolveModel(docketNumber) ?? "",
      });

      this.SET_PAGINATION({
        ...this.paginationData,
        page: 1,
        ...(this.isRunAllQueries ? { total_count: this.filteredSearchResults.length } : {}),
      });

      this.SET_FILTERS_APPLIED(!this.isFiltersEmpty);
      this.SET_SELECTED_CARD_ID(null);
    },

    clearFilters() {
      this.model.query.value = "";
      this.model.jurisdiction.value = [];
      this.model.dateRange = [];
      this.model.docketNumber.value = {};

      this.resetFilters();

      this.SET_PAGINATION({
        ...this.paginationData,
        page: 1,
        ...(this.isRunAllQueries ? { total_count: this.filteredSearchResults.length } : {}),
      });
    },

    handleClear() {
      this.model.dateRange = [];
    },

    closeFiltersPanel() {
      this.$emit("onClosePanel");
    },
  }
}
</script>

<style lang="scss" scoped>
.date-picker {
  width: 100%;
}
</style>
