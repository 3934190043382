<template>
  <div class="h-full">
    <div class="flex items-center gap-2 mb-4">
      <button
        v-if="!autorun"
        type="button"
        class="text-brand hover:text-brand-hover cursor-pointer flex-shrink-0 inline-flex items-center rounded-lg transition mr-1"
        @click="backToSearch"
      >
        <BackArrow />
      </button>
      <span class="font-semibold">vLex</span>
      <Loader v-if="isLoading || isCardLoading" :size="4" />
    </div>

    <div v-if="!isLoading" class="flex justify-between items-center mt-2">
      <p v-if="isRunAllQueries">vLex Output Results</p>
      <div v-else class="flex gap-2 items-center">
        <span class="transition-all duration-300 cursor-pointer text-gray-500 hover:border-gray-500 truncate text-sm font-bold">Result for </span>
        <button
          type="button"
          class="font-medium text-brand"
          @click="showSearchParameters"
        >
          "{{ resolvedSearchResultsTitle }}"
        </button>
        <span class="text-center flex-shrink-0 items-center text-sm text-gray-500 font-bold" v-if="lastRunDate"> Last Run <span class="text-gray-900 ml-1">{{ lastRunDate }}</span> </span>
        <button
          type="button"
          class="no-underline text-blue-600 ml-2 text-center text-sm px-1 transition-colors duration-300 py-0.5 hover:text-gray-800"
          @click="refreshData"
        >
          <font-awesome-icon icon="sync" class="cursor-pointer hover:text-blue-400 hover:animate-spin outline-none" v-b-tooltip.hover.top title="Load Fresh Data"> </font-awesome-icon>
        </button>
      </div>

    <div
      v-if="isFiltersInfoShown"
      class="ml-auto text-brand cursor-pointer flex gap-2 items-center"
      v-b-tooltip.hover.top
      title="Filters"
      @click="$emit('toggleFilterPanel', !isFiltersPanelShown)"
    >
      <span v-if="isFiltersApplied">Filter Results: </span>
      <button class="outline-none shadow border-solid border-gray-200 cursor-pointer py-2 px-2 rounded-lg text-sm flex items-center bg-white text-brand">
        <FilterIcon />
      </button>
      <div
        v-if="isFiltersApplied"
        class="px-0.5 py-1.5 h-6 w-6 mb-4 -ml-8 -mt-5 rounded-full flex text-center items-center bg-red-500 text-sm cursor-pointer"
        v-b-tooltip.hover.top
      >
        <div class="mx-auto text-m text-white">
          <span>{{ filteredSearchResults.length }}</span>
        </div>
      </div>
    </div>
    </div>

    <!-- TODO Fix style -->
    <div class="flex gap-4 mt-4" style="height: calc(100% - 144px);">
    <FiltersPanel
      v-show="isFiltersPanelShown"
      :key="filterPanelKey"
      @onClosePanel="$emit('toggleFilterPanel', false)"
    />

    <template v-if="!isLoading">
      <div
      class="flex h-full gap-2"
      :class="{'w-1/2': isCardSelected, 'w-full': !isCardSelected}"
      >
        <div
          v-if="searchResults.length > 0"
          class="h-full pl-2 overflow-y-auto scroll-bar overflow-y-auto"
        >
          <ResultCard
            v-for="card in searchResults"
            :key="`${card.data.id}-${card.doc_id}`"
            :card="card"
            :isSelected="card.data.id === selectedCardId"
            class="mb-4"
            @click="selectCard(card.data.id)"
          />
        </div>
        <div v-else>No results found!</div>

    <div
      v-if="filteredSearchResults?.length > 0 && !isFullView"
      class="absolute bottom-0 left-0 h-20 z-10 flex flex-wrap items-center justify-between px-6 bg-white"
      :class="{ 'w-1/2': isCardSelected, 'w-full': !isCardSelected }"
    >
      <div class="flex items-center">
        <label for="perPage" class="mr-1">Rows</label>
          <select
            name="perPage"
            v-model="itemsPerPage"
            class="w-20 h-full outline-none font-bold bg-white border border-solid border-gray-200 pl-2 pr-4 py-1 rounded"
            @change="onPerPageChange"
          >
            <option v-for="row in perPageOptions" :value="row" :key="row">{{ row }}</option>
          </select>
        </div>

        <neo-pagination
          v-model="pageNumber"
          :currentPage="paginationData.page"
          :totalPageCount="totalPages"
          @change="onPageChange"
        />
      </div>
    </div>

    <CardDetails
      v-if="isCardSelected"
      :isFullView="isFullView"
      :isViewDocument="isViewDocument"
      :isDetailsPrinting="isDetailsPrinting"
      :class="{ 'w-1/2': isCardSelected, 'w-full': !isCardSelected }"
      @onFullView="isFullView = true"
      @onViewDocument="viewDocument"
      @onDetailsPrinting="printDetails"
      @onCloseDetails="closeDetails"
    />

    <SearchParameters ref="search-parameters" />
    </template>

    <template v-else>
      <Skeleton :count="10" class="w-full" /> 
    </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import NeoPagination from "@shared/saas-components/pagination/index";
import Loader from "@shared/loader";
import ResultCard from "./ResultCard.vue";
import CardDetails from "./CardDetails.vue";
import FiltersPanel from "./FiltersPanel.vue";
import SearchParameters  from "./SearchParameters.vue";
import { BackArrow, FilterIcon } from "./icons";
import { formatCardDate } from "../utils";
import { VLEX_SCREENS, PER_PAGE_OPTIONS } from "../constants";
import { EventBus } from "@/main.js";

export default {
  name: "VLexSearchResults",

  components: {
    NeoPagination,
    ResultCard,
    CardDetails,
    FiltersPanel,
    SearchParameters,
    Loader,
    BackArrow,
    FilterIcon,
  },

  props: {
    isFiltersPanelShown: {
      type: Boolean,
      default: false,
    },
    filterPanelKey: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      autorun: false, // TODO
      isFullView: false,
      isDetailsPrinting: false,
      isViewDocument: false,
    }
  },

  computed: {
    ...mapGetters({
      getSelectedToolQuery: "getSelectedToolQuery",
      searchResults: "VLexStore/paginatedSearchResults",
      filteredSearchResults: "VLexStore/getFilteredSearchResults",
      paginationData: "VLexStore/getPaginationData",
      totalPages: "VLexStore/getTotalPages",
      selectedCardId: "VLexStore/getSelectedCardId",
      requestPayload: "VLexStore/getRequestPayload",
      queryData: "VLexStore/getQuery",
      searchOptions: "VLexStore/getSearchOptions",
      isFiltersApplied: "VLexStore/getIsFiltersApplied",
      isLoading: "VLexStore/getIsLoading",
      isCardLoading: "VLexStore/getIsCardLoading",
      isRunAllQueries: "VLexStore/getIsRunAllQueries",
    }),

    itemsPerPage: {
      get() {
        return this.paginationData.perPage;
      },
      set(perPage) {
        this.SET_PAGINATION({
          ...this.paginationData,
          page: 1,
          perPage
        });
      }
    },

    pageNumber: {
      get() {
        return this.paginationData.page;
      },
      set(page) {
        this.SET_PAGINATION({
          ...this.paginationData,
          page,
        });
      }
    },

    perPageOptions() {
      return PER_PAGE_OPTIONS;
    },

    resolvedSearchResultsTitle() {
      return this.selectedJurisdiction
        ? `${this.searchQueryString}, ${this.selectedJurisdiction}`
        : this.searchQueryString
    },

    searchQueryString() {
      return this.requestPayload?.query ?? "";
    },

    selectedJurisdiction() {
      if (this.requestPayload?.jurisdiction?.length > 0) {
        return this.searchOptions.jurisdiction
          ?.filter(el => this.requestPayload.jurisdiction?.includes(el?.value))
          ?.map(el => el?.name)
          ?.slice(0, 2) // show only first two countries from the list
      }

      return "";
    },

    lastRunDate() {
      return formatCardDate(this.searchQuery?.last_runtime);
    },

    searchQuery() {
      return this.queryData ?? {};
    },

    isFiltersInfoShown() {
      return true; // this.searchResults.length > 0; //(searchQueryExists || isAllRunQueries)
    },

    isCardSelected() {
      return Boolean(this.selectedCardId);
    }
  },

  methods: {
    ...mapMutations({
      SET_SCREEN: "VLexStore/SET_SCREEN",
      SET_SEARCH_RESULTS: "VLexStore/SET_SEARCH_RESULTS",
      SET_PAGINATION: "VLexStore/SET_PAGINATION",
      SET_SELECTED_CARD_ID: "VLexStore/SET_SELECTED_CARD_ID",
      SET_DOCUMENT: "VLexStore/SET_DOCUMENT",
      TOGGLE_LOADING: "VLexStore/TOGGLE_LOADING",
      SET_IS_RUN_ALL_QUERIES: "VLexStore/SET_IS_RUN_ALL_QUERIES",
      SET_SELECTED_CARD_STATUS: "VLexStore/SET_SELECTED_CARD_STATUS"
    }),
    ...mapActions({
      searchByRequestId: "VLexStore/searchByRequestId",
      resetFilters: "VLexStore/resetFilters",
      resetPagination: "VLexStore/resetPagination",
    }),

    backToSearch() {
      if (this.isLoading) return;
      this.SET_SCREEN(VLEX_SCREENS.Form);
      this.SET_SEARCH_RESULTS([]);
      this.SET_IS_RUN_ALL_QUERIES(false);
      this.resetState();
      this.resetPagination();
    },

    async onPerPageChange() {
      this.SET_SELECTED_CARD_ID(null);
      if (this.isRunAllQueries) return;
      this.TOGGLE_LOADING();
      await this.search();
      this.$emit("setFilterOptions");
      this.TOGGLE_LOADING();
    },

    async onPageChange() {
      this.SET_SELECTED_CARD_ID(null);
      if (this.isRunAllQueries) return;
      this.TOGGLE_LOADING();
      await this.search();
      this.$emit("setFilterOptions");
      this.TOGGLE_LOADING();
    },

    selectCard(cardId) {
      if (cardId === this.selectedCardId) return;
      this.closeDetails();
      this.SET_SELECTED_CARD_ID(cardId);
      this.SET_DOCUMENT(null);
    },

    viewDocument(payload) {
      this.isViewDocument = payload;
    },

    printDetails(payload) {
      this.isDetailsPrinting = payload;
    },

    closeDetails() {
      if (!this.isFullView) {
        this.SET_SELECTED_CARD_ID(null);
      }
      this.isViewDocument = false;
      this.isFullView = false;
    },

    async refreshData() {
      this.TOGGLE_LOADING();
      this.resetState();

      this.SET_PAGINATION({
        ...this.paginationData,
        page: 1,
      });
      
      await this.search();
      EventBus.$emit("onGetQueriesResultsCount");
      this.TOGGLE_LOADING();
    },

    async search() {
      await this.searchByRequestId({ headers: { "x-refresh": true } });
    },

    resetState() {
      this.SET_SELECTED_CARD_STATUS("all");
      this.SET_DOCUMENT(null);
      this.SET_SELECTED_CARD_ID(null);
      this.resetFilters();
      this.$emit("resetFilterModel");
    },

    showSearchParameters() {
      this.$refs["search-parameters"].$refs["search-parameters-content"].showModal();
    }
  }
}
</script>
