<template>
  <div class="flex justify-end items-center py-2 w-full">
      <div class="relative flex w-48 statuses-dropdown">
        <button
              @click.stop="toggleDropdown($event)"
              @blur.stop="closeDropdown"
              :disabled="!checkPermission('checkOutput.edit_card')"
              class="bg-transparent font-semibold rounded-lg text-sm px-2 py-1.5 text-center inline-flex items-center disabled:opacity-70 border-2 w-full"
              :class="getStatusButtonClasses(this.cardStatus)"
            >
              <div class="flex w-full justify-between">
                <div
                  v-if="cardStatus"
                  name="confirmed"
                  class="flex items-center justify-center h-5 w-5 rounded-full border-2"
                  :class="getBorderColorClass(this.cardStatus)"
                  style="background: #f0f8ea"
                >
                  <component :is="getCardStatusComponent()" />
                </div>
                <div class="truncate flex px-2">
                  {{ getCardStatusText }}
                </div>
                  <font-awesome-icon
                    :icon="isDropdownShown ? 'angle-up' : 'angle-down'"
                    class="h-5 w-5"
                  />
              </div>
            </button>

          <ul
              v-if="isDropdownShown"
              class="absolute top-9 z-50 w-full h-auto text-sm font-small bg-white shadow  rounded"
            >
              <li
                v-for="status in statusesMap"
                :key="status.name"
                @mousedown.stop="selectDropdown($event)"
                name="confirmed"
                class="p-2 flex items-center gap-4 border-b-2 border-gray-300 cursor-pointer"
              >
                <span
                  :name="status.name"
                  class="flex items-center justify-center h-6 w-6 rounded-full border-2"
                  :style="{ background: status.background, borderColor: status.borderColor }"
                >
                  <component :is="status.component" :name="status.name" />
                </span>
                <span name="confirmed" :style="{ color: status.color }">{{ status.label}}</span>
              </li>
          </ul>
      </div>
    </div>
</template>

<script>
import MinusIcon from "@shared/assets/minus-icon.svg";
import Check from "@shared/light-ray/assets/svg/check.svg";
import Question from "@shared/light-ray/assets/svg/potentialQuestionMark.svg";
import CrossRed from "@shared/light-ray/assets/svg/cross-red.svg";
import { checkPermission } from "@shared/utils/functions";
import { getStatusButtonClasses, getBorderColorClass, checkIsNoneStatus } from "./utils";
import { STATUSES, STATUSES_LIST } from "./constants.js";

export default {
  name: "CardStatus",

  components: {
    Check,
    MinusIcon,
    Question,
    CrossRed,
  },

  props: {
    cardStatus: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      isDropdownShown: false,
    }
  },

  computed: {
    statusesMap() {
      return [{
        name: "confirmed",
        label: "Confirmed",
        component: Check,
        color: "#67b52b",
        background: "#f0f8ea",
        borderColor: "#67b52b"
      }, {
        name: "potential",
        label: "Potential",
        component: Question,
        color: "#fc6713",
        background: "rgba(252, 103, 19, 0.11)",
        borderColor: "#fc6713"
      }, {
        name: "irrelevant",
        label: "False Matches",
        component: CrossRed,
        color: "#eb3131",
        background: "#fbeded",
        borderColor: "#eb3131"
      }, {
        name: "unresolved",
        label: "Unresolved",
        component: MinusIcon,
        color: "#8d8d8d",
        background: "#d7d7d7",
        borderColor: "#d7d7d7"
      }]
    },

    getCardStatusText() {
      if (!STATUSES_LIST.includes(this.cardStatus.toLowerCase())) return STATUSES.Unresolved;

      return this.cardStatus === 'IRRELEVANT'
        ? 'FALSE MATCHES'
        :  this.cardStatus === 'UNKNOWN'
          ? 'UNRESOLVED'
          : this.cardStatus === "None"
            ? "UNRESOLVED"
            : this.cardStatus;
    },
  },

  methods: {
    checkPermission,
    getStatusButtonClasses,
    getBorderColorClass,
    checkIsNoneStatus,

    toggleDropdown() {
      this.isDropdownShown = !this.isDropdownShown;
    },

    closeDropdown() {
      this.isDropdownShown = false
    },

    selectDropdown(event) {
      this.$emit("selectDropdown", event)
    },

    getCardStatusComponent() {
      const status = this.cardStatus.toLowerCase() 
      if (checkIsNoneStatus(status)) return MinusIcon;
      return this.statusesMap.find(el => el.name === status)?.component
    },
  }
}
</script>
